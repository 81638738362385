import React from 'react';
import Nav from './navbar';
import Footer from './footer';

export default function Intern(){
    function openNav() {
        document.getElementById("mySidepanel").style.width = "250px";
    }

    return(
        <>
            {/* loader */}
            <Nav />
            {/* End loader */}

            {/* Start Header */}
            <div className='padd_30'>
                <div className='full_bf'>
                    <header>
                        {/* Inner Header */}
                        <div className='head-top'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div className='logo'>
                                            <a href="/">พี เอส ที คอมพิวเตอร์</a>
                                        </div>
                                    </div>
                                    <div className='col-sm-9'>
                                        <ul className="email text_align_right ml-auto">
                                            <li> <button className="openbtn" onClick={openNav}><img src="assets/images/menu_btn.png"/></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
            {/* End Header */}

            {/* Start Job Details */}
            <div id='intern' className='cameras'>
                <section className='section-hero overlay inner-page bg-image' id='home-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <h1 className='text-black font-weight-bold'>นักศึกษาฝึกประสบการณ์</h1>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Job desctiption */}
                <section className='site-section'>
                    <div className='container'>
                        <div className='row align-item-center mb-5'>
                            <div className='col-lg-8 mb-4 mb-lg-0'>
                                <div className='d-flex align-item-center'>
                                    <div className='border p-2 d-inline-block mr-3 rounded'>
                                        <img src='assets/images/Career/4.png'/>
                                    </div>
                                    <div>
                                        <h2>นักศึกษาฝึกประสบการณ์</h2>
                                        <div>
                                            <span className='ml-0 mr-2 mb-2'><span className='fa fa-briefcase'></span> พี เอส ที คอมพิวเตอร์ สำนักงานใหญ่</span>
                                            <span className='m-2'><span className='fa fa-home'></span> อำเภอเมือง, ร้อยเอ็ด</span>
                                            <span className='m-2'><span className='fa fa-clock-o'></span> นักศึกษาฝึกประสบการณ์</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href='https://forms.gle/y6mRRKtwE9DDYGN98' className='btn btn-block btn-primary btn-md'>สมัครงาน</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Jobs Second Description */}
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='mb-5'>
                                    <figure className='mb-5'>
                                        <img src='assets/images/Career/job_single_img_1.jpg' alt='Image' className='img-fluid rounded'/>
                                    </figure>
                                    <h3 className='h5 d-flex align-item-center mb-4 text-black'>รายละเอียดงาน</h3>
                                    <p>นักศึกษาฝึกประสบการณ์ สำหรับนักศึกษาที่กำลังฝึกงานหรือฝึกประสบการณ์ในสาขาวิชาที่เกี่ยวข้อง หรือสามารถทำงานได้ในเวลาว่าง ที่สำนักงานใหญ่ อำเภอเมือง, ร้อยเอ็ด</p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='h5 d-flex align-item-center mb-4 text-black'><span className='fa fa-rocket mr-3'></span> การปฏิบัติงาน</h3>
                                    <ul className='list-unstyled m-0 p-0'>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> ซ่อมคอมพิวเตอร์/ปริ้นเตอร์/โน๊ตบุ๊ค/ติดตั้งกล้องวงจรปิด</li>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> ให้บริการลูกค้าด้วยความสุภาพและมีความรับผิดชอบต่อหน้าที่</li>
                                    </ul>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='h5 d-flex align-items-center mb-4 text-black'><span className='fa fa-book mr-3'></span>สวัสดิการ</h3>
                                    <ul className='list-unstyled m-0 p-0'>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> สวัสดิการตามกฎหมาย</li>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> ค่าแรง OT</li>
                                    </ul>
                                </div>

                                {/* Requirement / Education /Experience */}

                                <div className='mb-5'>
                                    <h3 className='h5 d-flex align-items-center mb-4 text-black'><span className='fa fa-user mr-3'></span>คุณสมบัติ</h3>
                                    <ul className='list-unstyled m-0 p-0'>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> นักศึกษา ระหว่างชั้น มัธยม หรือ ปวช, ปวส</li>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> สาขาวิชาที่เกี่ยวข้อง</li>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> สามารถทำงานเป็นทีม</li>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> ให้บริการลูกค้าด้วยความสุภาพและมีความรับผิดชอบต่อหน้าที่</li>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> มีความรับผิดชอบสูง</li>
                                        <li className='d-flex align-items-center mb-2'><span className='fa fa-check-square mr-2 text-primary'></span> มีทักษะการสื่อสารและการทำงานเป็นทีม</li>
                                    </ul>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='h5 d-flex align-items-center mb-4 text-black'><span className='fa fa-home mr-3'></span>สถานที่ปฏิบัติงาน</h3>
                                    <p>สำนักงานใหญ่ อำเภอเมือง ร้อยเอ็ด</p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='h5 d-flex align-items-center mb-4 text-black'><span className='fa fa-clock-o mr-3'></span>เวลาทำงาน</h3>
                                    <p>จันทร์ - เสาร์ 08:30 - 16:30</p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='h5 d-flex align-items-center mb-4 text-black'><span className='fa fa-users mr-3'></span>จำนวนที่รับ</h3>
                                    <p>6 อัตรา</p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='h5 d-flex align-items-center mb-4 text-black'><span className='fa fa-phone mr-3'></span>ติดต่อ</h3>
                                    <p>คุณวณารัตณ์ (อ้อย) โทร. 089-941-9531</p>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-6'>
                                        <a href='https://forms.gle/y6mRRKtwE9DDYGN98' className='btn btn-block btn-primary btn-md'>สมัครงาน</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Footer */}
            <Footer />
            {/* End Footer */}
        </>
    )
}