import React from 'react';
import Nav from './navbar';
import Footer from './footer';

function Home() {

    function openNav() {
        document.getElementById("mySidepanel").style.width = "250px";
    }

    const cameras = [
        { price: '7,500 บาท', name: 'ชุดกล้องวงจรปิด Hilook 2 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/12.png' },
        { price: '11,900 บาท', name: 'ชุดกล้องวงจรปิด Hi-view 4 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/4.png' },
        { price: '11,900 บาท', name: 'ชุดกล้องวงจรปิด HiLook 4 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/5.png' },
        { price: '12,000 บาท', name: 'ชุดกล้องวงจรปิด Hilook 4 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/13.png'},
        { price: '14,500 บาท', name: 'ชุดกล้องวงจรปิด Dahua 4 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/3.png' },
        { price: '16,500 บาท', name: 'ชุดกล้องวงจรปิด Hikivision Hybrid-Light 4 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/9.png' },
        { price: '18,900 บาท', name: 'ชุดกล้องวงจรปิด Hikivision Color-Vu 4 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/6.png' },
        { price: '22,900 บาท', name: 'ชุดกล้องวงจรปิด Hilook 8 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/14.png' },
        { price: '24,500 บาท', name: 'ชุดกล้องวงจรปิด Dahua 8 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/2.png' },
        { price: '25,500 บาท', name: 'ชุดกล้องวงจรปิด Hikivision Hybrid-Light 8 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/10.png' },
        { price: '32,900 บาท', name: 'ชุดกล้องวงจรปิด Hikivision Color-Vu 8 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/7.png' },
        { price: '44,500 บาท', name: 'ชุดกล้องวงจรปิด Dahua 16 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/1.png' },
        { price: '44,900 บาท', name: 'ชุดกล้องวงจรปิด Hilook 16 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/15.png' },
        { price: '48,500 บาท', name: 'ชุดกล้องวงจรปิด Hikivision Hybrid-Light 16 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/11.png' },
        { price: '69,900 บาท', name: 'ชุดกล้องวงจรปิด Hikivision Color-Vu 16 ตัว พร้อม เครื่องบันทึก', img: 'assets/images/8.png' },
    ]

    const services = [
        { price: 'เริ่มต้น 100 บาท', name: 'ซ่อมคอมพิวเตอร์', img: 'assets/images/pc.png' },
        { price: 'เริ่มต้น 100 บาท', name: 'ซ่อมปริ้นเตอร์', img: 'assets/images/printer.png' },
        { price: 'เริมต้น 100 บาท', name: 'ซ่อมโน๊ตบุ๊ค', img: 'assets/images/laptop.png' },
    ]

    return (
        <>
        {/* loader  */}
        <Nav />
        {/* End loder */}
        {/* header */}
        <div className="padd_30">
            <div className="full_bf">
                <header>
                    {/* header inner */}
                    <div className="head-top">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="logo">
                                        <a href="/">พี เอส ที คอมพิวเตอร์</a>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <ul className="email text_align_right ml-auto">
                                    <li> <button className="openbtn" onClick={openNav}><img src="assets/images/menu_btn.png"/></button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* end header */}
                {/* start slider section */}
                <div className = "banner_main">
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="container-max">
                                    <div className="carousel-caption relative">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='bluid'>
                                                    <h1><span>ศูนย์บริการ</span> <br/><span>ด้านกล้องวงจรปิด</span></h1>

                                                    <a className='read_more' href='#contact'>ติดต่อสอบถาม</a>
                                                    <a className='read_more' href='/career'>สมัครงาน</a>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="pan_img">
                                                        <figure><img src="assets/images/top_img.png" alt="#"/></figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                    <div className="container-max">
                                        <div className="carousel-caption relative">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='bluid'>
                                                        <h1><span>ศูนย์บริการ</span> <br/><span>ด้านคอมพิวเตอร์</span></h1>

                                                        <a className='read_more' href='#contact'>ติดต่อสอบถาม</a>
                                                        <a className='read_more' href='/career'>สมัครงาน</a>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="pan_img">
                                                            <figure><img src="assets/images/top_2.png" alt="#"/></figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="container-max">
                                        <div className="carousel-caption relative">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='bluid'>
                                                        <h1><span>ศูนย์บริการ</span> <br/><span>ครบวงจร ด้าน IT</span></h1>
                                                        
                                                        <a className='read_more' href='#contact'>ติดต่อสอบถาม</a>
                                                        <a className='read_more' href='/career'>สมัครงาน</a>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="pan_img">
                                                            <figure><img src="assets/images/top_3.png" alt="#"/></figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        {/* end slider section */}
        {/* services */}
        <div id='services' className='cameras'>
            <div className='container-max'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='titlepage text_align_center'>
                            <h2>บริการของทางร้าน</h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {services.map((service, index) => (
                        <div className='col-md-4' key={index}>
                            <div id='ho_bg' className='cameras_text'>
                                <div className='cameras_img text_align_center'>
                                    <figure><img src={service.img} alt="#" /></figure>
                                </div>
                                <p>ราคา<br /><span>{service.price}</span></p>
                                <h3>{service.name}</h3>
                                <a className="read_more" href='https://lin.ee/vRLgcxS'>ติดต่อสอบถาม</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        {/* cameras */}
        <div id='cameras' className='cameras'>
            <div className='container-max'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='titlepage text_align_center'>
                            <h2>แคตตาล๊อกสินค้ากล้องวงจรปิด</h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {cameras.map((camera, index) => (
                        <div className='col-md-4' key={index}>
                            <div id='ho_bg' className='cameras_text'>
                                <div className='cameras_img text_align_center'>
                                    <figure><img src={camera.img} alt="#" /></figure>
                                </div>
                                <p>ราคา<br /><span>{camera.price}</span></p>
                                <h3>{camera.name}</h3>
                                <a className="read_more" href='https://lin.ee/vRLgcxS'>ติดต่อสอบถาม</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        {/* end cameras */}
        {/* about */}
        <div id='about' className='about'>
            <div className='container'>
                <div className='row d_flex d_gird'>
                    <div class="col-md-6">
                        <figure>
                            <figure><img class="img_responsive" src="assets/images/about1.png" alt="#"/></figure>
                        </figure>
                    </div>
                </div>
                <div className='col-md-5 offset-md-1 order_1_mobile'>
                    <div className='titlepage text_align_left'>
                        <h2>บริการของทางร้าน</h2>
                        <p>
                        ยินดีต้อนรับทุกท่าน เราคือทีมงานที่มีความเชี่ยวชาญในการซ่อมแซมและดูแลรักษาคอมพิวเตอร์และระบบกล้องวงจรปิด (CCTV) ในพื้นที่ร้อยเอ็ด พร้อมทั้งให้บริการความช่วยเหลือทางเทคนิคที่มีคุณภาพสูง
                        เราไม่เพียงเพียงมีความเชี่ยวชาญในการแก้ไขปัญหาทุกชนิดของคอมพิวเตอร์และอุปกรณ์เทคโนโลยี แต่ยังให้บริการดูแลรักษาและติดตั้งระบบกล้องวงจรปิดที่มีคุณภาพเยี่ยม
                        </p>
                        <a className='read_more' href='#about'>อ่านเพิ่มเติม</a>
                    </div>
                </div>
            </div>
        </div>
        {/* end about */}
        {/* project */}
        <div className="choose">
            <div className="choose_bg">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='titlepage text_align_center'>
                                <h2>ทำไมถึงต้องเลือกพวกเรา</h2>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3 col-sm-6'>
                            <div className='comp text_align_left'>
                                <i><img src='assets/images/cho_icon1.png' alt='#'/></i>
                                <h3>500+</h3>
                                <span>ครัวเรือน และ บริษัท</span>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6'>
                            <div className='comp text_align_left'>
                                <i><img src='assets/images/cho_icon2.png' alt='#'/></i>
                                <h3>100%</h3>
                                <span>คุณภาพสูง และ ได้มาตราฐาน</span>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6'>
                            <div className='comp text_align_left'>
                                <i><img src='assets/images/cho_icon3.png' alt='#'/></i>
                                <h3>24/7</h3>
                                <span>บริการดูแล และ ซ่อมบำรุง</span>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6'>
                            <div className='comp text_align_left'>
                                <i><img src='assets/images/cho_icon4.png' alt='#'/></i>
                                <h3>100%</h3>
                                <span>ความพึงพอใจ จากลูกค้า</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-with'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div class='cctv'>
                            <figure><img src="assets/images/cctv.png" alt="#"/></figure>
                            <a className='read_more' href='#about'>อ่านเพิ่มเติม</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* end about */}
        {/* clients */}
        <div id="clients" className='clients'>
            <div className='clients_bg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='titlepage text_align_center'>
                                <h2>คอมเม้นลูกค้าของเรา</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* start slider */}
        <div id="testi" className='carousel slide clients_banner' data-ride='carousel'>
            <ol className='carousel-indicators'>
                <li data-target="#testi" data-slide-to="0" class="active"></li>
                <li data-target="#testi" data-slide-to="1"></li>
                <li data-target="#testi" data-slide-to="2"></li>
            </ol>
            <div className='carousel-inner'>
                <div className='carousel-item active'>
                    <div className='container'>
                        <div className='carousel-caption testi'>
                            <div className='row d_flex'>
                                <div className='col-md-12'>
                                    <div className='cli_flef'>
                                        <div className='pro_file'>
                                            <i><img class="text_align_right" src="assets/images/test1.png" alt="#"/></i>
                                        </div>
                                        <div className='test_box text_align_left'>
                                            <h4>ลูกค้า#1 <img src="assets/images/ter.png" alt="#"/></h4>
                                            <p>อยากให้ลองใช้บริการดูครับ ราคากับคุณภาพที่แลกมาคุ้มค่ามากๆ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='carousel-item'>
                    <div className='container'>
                        <div className='carousel-caption testi'>
                            <div className='row d_flex'>
                                <div className='col-md-12'>
                                    <div className='cli_flef'>
                                        <div className='pro_file'>
                                            <i><img src="assets/images/test2.png" alt="#"/></i>
                                        </div>
                                        <div className='test_box text_align_left'>
                                            <h4>ลูกค้า#2 <img src="assets/images/ter.png" alt="#"/></h4>
                                            <p>บริการดีมาก เก็บงานได้เรียบร้อย คุ้มค่ากับสินค้าที่ได้รับ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='carousel-item'>
                    <div className='container'>
                        <div className='carousel-caption testi'>
                            <div className='row d_flex'>
                                <div className='col-md-12'>
                                    <div className='cli_flef'>
                                        <div className='pro_file'>
                                            <i><img src="assets/images/test3.png" alt="#"/></i>
                                        </div>
                                        <div className='test_box text_align_left'>
                                            <h4>ลูกค้า#3 <img src="assets/images/ter.png" alt="#"/></h4>
                                            <p>บริการดีมากๆ ราคาไม่แพง เป็นกันเองและแนะนำได้อย่างเชี่ยวชาญสุดๆ</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#testi" role="button" data-slide="prev">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#testi" role="button" data-slide="next">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        {/* end clients */}
        {/* contact */}
        <div id='contact' className='contact'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='titlepage text_align_center'>
                            <h2>ติดต่อสอบถาม/นัดจองเวลา</h2>
                        </div>
                    </div>
                </div>
                <div className='row d_flex'>
                    <div className='col-md-6'>
                        <form id="request" class="main_form">
                            <div class="row">
                                <div class="col-md-12 ">
                                <input class="contactus" placeholder="ชื่อ" type="type" name=" Name"/> 
                                </div>
                                <div class="col-md-12">
                                <input class="contactus" placeholder="อีเมล" type="type" name="Email"/>                          
                                </div>
                                <div class="col-md-12">
                                <input class="contactus" placeholder="เบอร์โทรศัพท์" type="type" name="Phone Number"/>                          
                                </div>
                                <div class="col-md-12">
                                <textarea class="textarea" placeholder="ข้อความ" type="type" Message="Name"></textarea>
                                </div>
                                <div class="col-md-12">
                                <button class="send_btn">ส่งข้อความ</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-6'>
                        <div className='contact_img'>
                            <figure><img src="assets/images/contact_img.png" alt="#"/></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* end contact */}
        {/* Footer */}
        <Footer />
        {/* End Footer */}
        </>
    );
}

export default Home;