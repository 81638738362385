import React, { useEffect, useState } from 'react';

export default function Footer() {
    return (
        <footer>
            <div className='footer'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-3 col-sm-6'>
                            <div className='Informa'>
                                <a className='logo_footer' href='/'>พีเอสที คอมพิวเตอร์</a>
                                <p>"การซ่อมแซมคอมพิวเตอร์และการติดตั้งกล้องวงจรปิดที่มีความเป็นมืออาชีพ สร้างความมั่นใจและความปลอดภัยให้คุณ ที่ PSTCOMPUTER พวกเราเชื่อในการบริการที่ดีที่สุด เพื่อให้ทุกท่านสามารถใช้เทคโนโลยีได้อย่างมั่นใจและสะดวกสบายทุกวัน."</p>
                            </div>
                        </div>
                        
                        <div className='col-md-3 col-sm-6'>
                            <div className='Informa helpful'>
                                <h3>การบริการ</h3>
                                <ul>
                                    <li><a href="/">หน้าหลัก</a></li>
                                    <li><a href="#about">ประวัติร้าน</a></li>
                                    <li><a href="#services">บริการ ภายในร้าน</a></li>
                                    <li><a href="#cameras">บริการ กล้องวงจรปิด</a></li>
                                    <li><a href="#clients">คอมเม้นลูกค้า</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-6'>
                            <div className='Informa conta'>
                                <h3>ติดต่อสอบถาม</h3>
                                <ul>
                                    <li> <a href="Javascript:void(0)"><i class="fa fa-phone" aria-hidden="true"></i> 081-964-3830, 089-941-9531
                                        </a>
                                    </li>
                                    <li> <a href="pst.com8595@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> pst.com8595@gmail.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ul class="social_icon text_align_center">
                                <li> <a href="https://www.facebook.com/pstcom101"><i class="fa fa-facebook-f"></i></a></li>
                                <li> <a href="https://www.facebook.com/pst.com8595"><i class="fa fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="copyright text_align_center">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <p>© 2023 All Rights Reserved. Design by  <a href="/">PSTCOMPUTER.Dev</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}