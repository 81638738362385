import React from 'react';
import Nav from './navbar';
import Footer from './footer';

function Career() {

    function openNav() {
        document.getElementById("mySidepanel").style.width = "250px";
    }

    return (
        <>
            {/* loader */}
            <Nav />
            {/* end loader */}

            {/* Header */}
            <div className='padd_30'>
                <div className='full_bf'>
                    <header>
                        {/* Header Inner */}
                        <div className="head-top">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="logo">
                                            <a href="/">พี เอส ที คอมพิวเตอร์</a>
                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <ul className="email text_align_right ml-auto">
                                        <li> <button className="openbtn" onClick={openNav}><img src="assets/images/menu_btn.png"/></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* End Header */}

                    {/* Start slider section */}
                    <div className = "banner_main">
                        <div id="myCarousel" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="container-max">
                                        <div className="carousel-caption relative">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='bluid'>
                                                        <h1><span>Above and</span> <br/><span>Beyond</span></h1>
                                                        <h1><span>รับผิดชอบ ทะเยอทะยาน นำสู่</span> <br/><span>ความสำเร็จ</span></h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End slider section */}
                </div>
            </div>

            {/* Jobs */}
            <div id='jobs' className='cameras'>
                <section className='site-section'>
                    <div className='container-max'>

                        {/* Jobs Table Description */}
                        <div className="row mb-5 justify-content-center">
                            <div className='col-md-7 text-center'>
                                <h2 className='section-title mb-2'>ตำแหน่งงานที่เปิดรับ ฝึกงาน และ งานประจำ</h2>
                            </div>
                        </div>

                        {/* jobs product */}
                        <ul className='job-listings mb-5'>
                            
                            <li className='job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center'>
                                <a href='/engineer'></a>
                                <div className='job-listing-logo'>
                                    <img src='assets/images/Career/1.png' alt='Image' className='img-fluid' />
                                </div>

                                {/* Job short description */}
                                <div className='job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4'>
                                    <div className='job-listing-position custom-width w-50 mb-3 mb-sm-0'>
                                        <h2>ช่างติดตั้งกล้องวงจรปิด</h2>
                                        <strong>สำนักงานใหญ่ พี เอส ที คอมพิวเตอร์</strong>
                                    </div>
                                    <div className='job-listing-location mb-3 mb-sm-0 custom-width w-25'>
                                        <span className='icon-room'></span> อำเภอเมือง, ร้อยเอ็ด
                                    </div>
                                    <div className='job-listing-meta'>
                                        <span className='badge badge-success'>พนักงานประจำ</span>
                                    </div>
                                </div>
                            </li>

                            <li className='job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center'>
                                <a href='/engineer'></a>
                                <div className='job-listing-logo'>
                                    <img src='assets/images/Career/2.png' alt='Image' className='img-fluid' />
                                </div>

                                {/* Job short description */}
                                <div className='job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4'>
                                    <div className='job-listing-position custom-width w-50 mb-3 mb-sm-0'>
                                        <h2>ช่างซ่อมคอมพิวเตอร์</h2>
                                        <strong>สำนักงานใหญ่ พี เอส ที คอมพิวเตอร์</strong>
                                    </div>
                                    <div className='job-listing-location mb-3 mb-sm-0 custom-width w-25'>
                                        <span className='icon-room'></span> อำเภอเมือง, ร้อยเอ็ด
                                    </div>
                                    <div className='job-listing-meta'>
                                        <span className='badge badge-success'>พนักงานประจำ</span>
                                    </div>
                                </div>
                            </li>

                            <li className='job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center'>
                                <a href='/engineer'></a>
                                <div className='job-listing-logo'>
                                    <img src='assets/images/Career/3.png' alt='Image' className='img-fluid' />
                                </div>

                                {/* Job short description */}
                                <div className='job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4'>
                                    <div className='job-listing-position custom-width w-50 mb-3 mb-sm-0'>
                                        <h2>ช่างซ่อมอุปกรณ์ IT</h2>
                                        <strong>สำนักงานใหญ่ พี เอส ที คอมพิวเตอร์</strong>
                                    </div>
                                    <div className='job-listing-location mb-3 mb-sm-0 custom-width w-25'>
                                        <span className='icon-room'></span> อำเภอเมือง, ร้อยเอ็ด
                                    </div>
                                    <div className='job-listing-meta'>
                                        <span className='badge badge-success'>พนักงานประจำ</span>
                                    </div>
                                </div>
                            </li>

                            <li className='job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center'>
                                <a href='/intern'></a>
                                <div className='job-listing-logo'>
                                    <img src='assets/images/Career/4.png' alt='Image' className='img-fluid' />
                                </div>

                                {/* Job short description */}
                                <div className='job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4'>
                                    <div className='job-listing-position custom-width w-50 mb-3 mb-sm-0'>
                                        <h2>นักศึกษาฝึกงาน ปวช./ปวส. (ภาคฤดูร้อน)</h2>
                                        <strong>สำนักงานใหญ่ พี เอส ที คอมพิวเตอร์</strong>
                                    </div>
                                    <div className='job-listing-location mb-3 mb-sm-0 custom-width w-25'>
                                        <span className='icon-room'></span> อำเภอเมือง, ร้อยเอ็ด
                                    </div>
                                    <div className='job-listing-meta'>
                                        <span className='badge badge-danger'>นักศึกษาฝึกงาน</span>
                                    </div>
                                </div>
                            </li>

                            <li className='job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center'>
                                <a href='/intern'></a>
                                <div className='job-listing-logo'>
                                    <img src='assets/images/Career/4.png' alt='Image' className='img-fluid' />
                                </div>

                                {/* Job short description */}
                                <div className='job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4'>
                                    <div className='job-listing-position custom-width w-50 mb-3 mb-sm-0'>
                                        <h2>นักศึกษาฝึกงาน ปวช./ปวส. (ศึกษาดูงาน)</h2>
                                        <strong>สำนักงานใหญ่ พี เอส ที คอมพิวเตอร์</strong>
                                    </div>
                                    <div className='job-listing-location mb-3 mb-sm-0 custom-width w-25'>
                                        <span className='icon-room'></span> อำเภอเมือง, ร้อยเอ็ด
                                    </div>
                                    <div className='job-listing-meta'>
                                        <span className='badge badge-danger'>นักศึกษาฝึกงาน</span>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </section> 
            </div>
            {/* End Jobs */}

            {/* Footer */}
            <Footer />
            {/* End Footer */}
        </>
    )
}

export default Career;