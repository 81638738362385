import React, { useEffect, useState } from 'react';

export default function Nav(){
      
    function closeNav() {
        document.getElementById("mySidepanel").style.width = "0";
    }

    return(
        <>
        {/* loader */}
        <div className="loader_bg">
            <div className='loader'><img src='assets/img/loading.gif' alt="" /></div>
        </div>
        {/* End loader */}
        <div id="mySidepanel" className="sidepanel">
            <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>×</a>
            <a href="/">หน้าหลัก</a>
            <a href="#about">ประวัติร้าน</a>
            <a href="#services">บริการ ภายในร้าน</a>
            <a href="#cameras">บริการ กล้องวงจรปิด</a>
            <a href="#clients">คอมเม้นลูกค้า</a>
            <a href="#contact">ติดต่อสอบถาม</a>
            <a href="/career">สมัครงาน</a>
        </div>
        </>
    )
}