import {BrowserRouter, Routes, Route} from 'react-router-dom';
import React from 'react';
import Home from './components/home';
import Career from './components/career';
import Engineer from './components/engineer';
import Intern from './components/intern';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') disableReactDevTools();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/career" element={<Career />} />
        <Route path="/engineer" element={<Engineer />} />
        <Route path="/intern" element={<Intern />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
